import React from "react"

import { DocumentDisplay } from "../../components/common"
import privacyPolicyDocument from "./privacyPolicyDocument"

const PrivacyPolicy = () => {
  return (
    <div className="mb-4">
      <DocumentDisplay doc={privacyPolicyDocument} />
    </div>
  )
}

export default PrivacyPolicy
