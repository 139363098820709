export default [
  {
    type: "section",
    content: [
      {
        header1: "PRIVACY POLICY ",
      },
      {
        paragraph: "Last updated 25 Feb 2020",
      },
      {
        paragraph:
          "Bit More Media Limited (company number 09673233) (“us”, “our”, “we” “Bit More Media”) is the controller of your personal data collected through the App. Bit More Media is committed to protecting and respecting your privacy.",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "1.	Introduction",
      },
      {
        paragraph:
          "This privacy policy (the “Privacy Policy”) sets out the types of personal data we collect and use when you access and visit the alvyApp or our website: [insert website URL] (together, henceforth known as the “App”) and how we may manage use that data.",
      },
      {
        paragraph:
          "For ease of reading, we have divided this Privacy Policy into the following sections:",
      },
      {
        list: [
          "1.	Introduction",
          "2.	What information can we collect?",
          "3.	How is your personal information collected?",
          "4.	How and why do we use and share your personal information?  ",
          "5.	For how long do we keep your personal information?",
          "6.	Security",
          "7.	International Data Transfers",
          "8.	Your Rights",
          "9.	Contact Details    	",
        ],
      },
      {
        paragraph:
          "It is important that you read this Privacy Policy together with any other privacy notice or fair processing notices that we may provide on the App at or around the time that we collect or process personal data about you (for example, fair processing notices that we may display to you at the time that you sign up to receive e-mail updates from us) so that you are fully aware of how and why we are using that data.  ",
      },
      {
        paragraph:
          "This Privacy Policy supplements other notices on our App and is not intended to override or replace them. ",
      },
      {
        paragraph:
          "By visiting or otherwise using our App, you agree to its terms (including as amended from time to time) and this Privacy Policy.  If, for any reason, you do not agree to the terms of this Privacy Policy, please stop using this App.",
      },
      {
        paragraph:
          "We reserve the right to revise or amend this Privacy Policy at any time to reflect changes to our business or changes in the law.  Where these changes are significant, we will endeavour to let users of the App know. However, it is your responsibility to check this Privacy Policy before each use of the App – for ease of reference the top of this Privacy Policy indicates the date on which it was last updated.",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "2.	What information can we collect?",
      },
      {
        header3: "What is personal data?",
      },
      {
        paragraph:
          "Where this Privacy Policy refers to ‘personal data’ it is referring to data about you from which you could be identified – such as your name, your date of birth, your contact details and even your IP address. ",
      },
      {
        paragraph:
          "By law all organisations who process your personal data in Europe are obliged to process your personal data in certain ways and to ensure that you are given an appropriate amount of information about how they use it. You also have various rights to seek information from those organisations about how they are using your data, and to prevent them from processing it unlawfully. For more information about these rights, please see the ‘Your Rights’ section of this Privacy Policy.",
      },
      {
        header3: "What types of data we collect from you when you use the App?",
      },
      {
        paragraph: "The personal data we collect from you may include:",
      },
      {
        list: [
          "•	Identity Data which includes your name, date of birth and gender.",
          "•	Contact Data which includes your e-mail address, billing address and delivery address. ",
          "•	Content Data which includes information stored on your Device. ",
          "•	Device Data which includes your mobile device you use, your mobile operating system and information about the device you are using when you use alvyApp.",
          "•	Financial Data which includes your bank account and payment card details. ",
          "•	Transaction Data which includes details about payments to and from you and other details or services you have purchased from us. ",
          "•	Technical Data which includes your IP address, your login data and browser type and version. ",
          "•	Profile Data which includes your username and password, your interests, preferences, feedback and survey responses.",
          "•	Usage Data which includes information about how you use alvyApp. ",
          "•	Marketing and Communications Data which includes your preferences in receiving marketing from us and our third parties.       ",
        ],
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "3.	How is your personal information collected?",
      },
      {
        header3: "Information you give to us",
      },
      {
        paragraph:
          "When you use the App to register your account with us, complete a form, request marketing be sent to you, participate in social media functions, contact us by email or by post, take part in an online survey, post a comment, enter into competitions, report a problem or offer information to us directly via the App, we may collect, store and use the personal data that you disclose to us. If you want to update the information you have previously given to us, please contact us.",
      },
      {
        header3: "Automated technologies or interactions",
      },
      {
        paragraph:
          "Each time you use our Apps we will automatically collect personal data including Device, Content and Usage Data.  We collect this data using technologies such as cookies or other similar tracking technologies.  ",
      },
      {
        paragraph:
          "We use this data for several different reasons. Firstly, we use it to ensure that the App works properly and that you are able to receive the full benefit of it. Second, we use the data to monitor online traffic and audience participation across the App. We undertake both of these activities because we have a legitimate interest in doing so.",
      },
      {
        paragraph: "Please refer to our Cookies Policy for further details. ",
      },
      {
        header3: "Third parties or publicly available sources",
      },
      {
        paragraph:
          "We may also receive personal data about you from our third-party partners such as: analytics providers, advertising networks, social media providers and search information providers. ",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "4.	How and why do we use/share your personal data?",
      },
      {
        header3: "Lawful basis for processing your information",
      },
      {
        paragraph:
          "We will only use your personal data when the law allows us to.  Most commonly we will use your personal data in the following circumstances: ",
      },
      {
        list: [
          "•	Where you have asked us to do so, or consented to us doing so;",
          "•	Where we need to do so in order to perform a contract, we have entered into with you;",
          "•	Where it is necessary for our legitimate interests (or those of a third party) and your fundamental rights do not override those interests; and",
          "•	Where we need to comply with a legal or regulatory obligation.",
        ],
      },
      {
        paragraph:
          "Here are some examples about how we may use the information we collect about you and the lawful basis we rely on to do so. ",
      },
      {
        table: {
          headerRow1: [
            "Activity",
            "Examples of the types of personal data we may collect",
            "Lawful basis for processing",
          ],
          row1: [
            "To install the App and register you as a new App user.",
            "Identity, contact, profile, usage, marketing and communications and technical information.",
            "Your consent",
          ],
          row2: [
            "To manage our relationship with your and notify you of changes to the App.",
            "Identity, contact, profile and marketing communications information.",
            "Your consent. Performance of a contract with you. Necessary for our legitimate interests (to keep records updated and to analyse how customers use our services). Necessary to comply with legal obligations (to inform you of any changes to our terms and conditions).",
          ],
          row3: [
            "To administer and protect our business and this App (including troubleshooting, data analysis and system testing.)",
            "Identity, contact, technical and device information.",
            "Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security)",
          ],
          row4: [
            "To deliver relevant content and advertisements to you.",
            "Identity, contact, profile, usage, device, content marketing and communications and technical information.",
            "Consent. Necessary for our legitimate interests (to develop our products/Services and grow our business)",
          ],
          row5: [
            "To use data analytics to improve our App, services, marketing, customer relationships and experiences",
            "Technical, device and usage information.",
            "Consent. Necessary for our legitimate interests (to develop our products/Services and grow our business)",
          ],
        },
      },
      {
        header3: "Marketing",
      },
      {
        paragraph:
          "We may use your personal data to form a view on what we think you may want or need, or what may be of interest to you.  This is how we decide which services and offers may be relevant for you (we call this marketing).",
      },
      {
        paragraph:
          "You will only receive marketing communications from us if you have provided us with your positive opt-in consent.",
      },
      {
        paragraph:
          "We will get your explicit opt-in consent before we share your personal data with any third party for marketing purposes.",
      },
      {
        header3: "Opting Out of Marketing",
      },
      {
        paragraph:
          "To unsubscribe from marketing emails at any time, please click on the unsubscribe link at the bottom of any marketing email. You may also contact us or the third party directly if you do not wish to receive any marketing materials from us or them.",
      },
      {
        header3: "Sharing your personal data",
      },
      {
        paragraph:
          "Depending on how and why you provide us with your personal data we may share it in the following ways:",
      },
      {
        list: [
          "•	we may share your personal data with any member of our company group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006;",
          "•	with selected third parties who we sub-contract to provide various services and/or aspects of the App’s functionality, such as where third-party plugins provide functionality such as message boards or image hosting services (see “Service Providers” below); and ",
          "•	with analytics and search engine providers that assist us in the improvement and optimisation of this App as described above.",
        ],
      },
      {
        paragraph:
          "We may also disclose your personal data to third parties in the following events:",
      },
      {
        list: [
          "•	if we were to sell or buy any business or assets, in which case we might disclose your personal data to the prospective seller or buyer of such business or assets as part of that sale;",
          "•	if Bit More Media or substantially all of its assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets;",
          "•	if we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or if we are asked to provide your details to a lawful authority in order to aid in the investigation of crime or disorder; and/or",
          "•	in order to enforce or apply our App’s terms of use or terms and conditions; or to protect the rights, property, or safety of our company, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.",
        ],
      },
      {
        header3: "Service Providers",
      },
      {
        paragraph:
          "Our service providers provide us with a variety of administrative, statistical, and technical services. We will only provide service providers with the minimum amount of personal data they need to fulfil the services we request, and we stipulate that they protect this data and do not use it for any other purpose. We take these relationships seriously and oblige all of our data processors to sign contracts with us that clearly set out their commitment to respecting individual rights, and their commitments to assisting us to help you exercise your rights as a data subject.  The following is a list of our major service providers:",
      },
      {
        list: [
          "•	Mailchimp (e-mail marketing)",
          "•	Google (app development and analytics)",
          "•	Facebook (including Instagram) (social media marketing)",
          "•	Twitter (social media marketing)",
          "•	Apple (App retailer)",
        ],
      },
      {
        header3: "Links to third-party sites ",
      },
      {
        paragraph:
          "Where we provide links to third party web sites, plug-ins and applications that are not affiliated with the App such sites are out of our control and are not covered by this Privacy Policy. If you access third-party sites using the links provided, the operators of these sites may collect personal data from you that could be used by them, in accordance with their own privacy policies.  Please check these policies before you submit any personal data to those third-party sites. ",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "5.	For how long do we keep your personal data?",
      },
      {
        paragraph:
          "We will hold your personal information on our systems only for as long as required to provide you with the services you have requested, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements.  We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.",
      },
      {
        paragraph:
          "In some circumstances you can ask us to delete your data: see ‘Your Rights’ below for further information.",
      },
      {
        paragraph:
          "In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "6.	Security",
      },
      {
        paragraph:
          "Bit More Media takes the protection of your information very seriously. We have put in place appropriate security measure to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed, including use of secure servers and passwords.  Where we have given you a password that enables you to access certain parts of our App, you are responsible for keeping this password confidential.  We ask you not to share a password with anyone. ",
      },
      {
        paragraph:
          "In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know.  They will only process your personal data on our instructions, and they are subject to a duty of confidentiality. ",
      },
      {
        paragraph:
          "We will notify you and any applicable regulator of a personal data breach where we are legally required to do so. ",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "7.	International Data Transfers",
      },
      {
        paragraph:
          "Please note that some of our service providers may be based outside of the European Economic Area (the “EEA”).  These service providers may work for us or for one of our suppliers and may be engaged in, among other things, the fulfilment of your request for information and the provision of services or support services.",
      },
      {
        paragraph:
          "Where we transfer your data to a service provider that is outside of the EEA, we seek to ensure that appropriate safeguards are in place to make sure that your personal data is held securely and that your rights as a data subject are upheld.  Transfers of personal data are made: ",
      },
      {
        list: [
          "•	to a country recognised by the European Commission as providing an adequate level of protection; or",
          "•	to a country which does not offer adequate protection but whose transfer has been governed by the standard contractual clauses of the European Commission or by implementing other appropriate cross-border transfer solutions to provide adequate protection.",
        ],
      },
      {
        paragraph:
          "By submitting your personal information, you agree to this transfer, storing or processing. If you would like more information about how the mechanism via which your personal data is transferred, please contact us.",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "8.	Your Rights",
      },
      {
        paragraph:
          "As a data subject you have a number of rights in relation to your personal data. Below, we have described the various rights that you have, as well as how you can exercise them.",
      },
      {
        header3: "Right of Access",
      },
      {
        paragraph:
          "You may, at any time, request access to the personal data that we hold which relates to you (you may have heard of this right being described as a 'subject access request'). ",
      },
      {
        paragraph:
          "Please note that this right entitles you to receive a copy of the personal data that we hold about you in order to enable you to check that it is correct and to ensure that we are processing that personal data lawfully. It is not a right that allows you to request personal data about other people, or a right to request specific documents from us that do not relate to your personal data.",
      },
      {
        paragraph:
          "You can exercise this right at any time by writing to us by contacting us and telling us that you are making a subject access request. You do not have to fill in a specific form to make this kind of request.",
      },
      {
        header3: "Your Right to Rectification and Erasure",
      },
      {
        paragraph:
          "You may, at any time, request that we correct personal data that we hold about you which you believe is incorrect or inaccurate. You may also ask us to erase personal data if you do not believe that we need to continue retaining it (you may have heard of this right described as the “right to be forgotten”).",
      },
      {
        paragraph:
          "Please note that we may ask you to verify any new data that you provide to us and may take our own steps to check that the new data you have supplied us with is right. Further, we are not always obliged to erase personal data when asked to do so; if for any reason we believe that we have a good legal reason to continue processing personal data that you ask us to erase we will tell you what that reason is at the time we respond to your request.",
      },
      {
        paragraph:
          "You can exercise this right at any time by contacting us and telling us that you are making a request to have your personal data rectified or erased and on what basis you are making that request. If you want us to replace inaccurate data with new data, you should tell us what that new data is. You do not have to fill in a specific form to make this kind of request.",
      },
      {
        header3: "Your Right to Restrict Processing",
      },
      {
        paragraph:
          "Where we process your personal data on the basis of a legitimate interest (see the sections of this Privacy Policy which explains how and why we use your information) you are entitled to ask us to stop processing it in that way if you feel that our continuing to do so impacts on your fundamental rights and freedoms or if you feel that those legitimate interests are not valid.",
      },
      {
        paragraph:
          "You may also ask us to stop processing your personal data (a) if you dispute the accuracy of that personal data and want us verify that data's accuracy; (b) where it has been established that our use of the data is unlawful but you do not want us to erase it; (c) where we no longer need to process your personal data (and would otherwise dispose of it) but you wish for us to continue storing it in order to enable you to establish, exercise or defend legal claims.",
      },
      {
        paragraph:
          "Please note that if for any reason we believe that we have a good legal reason to continue processing personal data that you ask us to stop processing, we will tell you what that reason is, either at the time we first respond to your request or after we have had the opportunity to consider and investigate it.",
      },
      {
        paragraph:
          "You can exercise this right at any time by writing to us using the contact details set out here and telling us that you are making a request to have us stop processing the relevant aspect of your personal data and describing which of the above conditions you believe is relevant to that request. You do not have to fill in a specific form to make this kind of request.",
      },
      {
        header3: "Your Right to Portability",
      },
      {
        paragraph:
          "Where you wish to transfer certain personal data that we hold about you, which is processed by automated means, to a third party you may write to us and ask us to provide it to you in a commonly used machine-readable format.",
      },
      {
        paragraph:
          "Because of the kind of work that we do and the systems that we use, we do not envisage this right being particularly relevant to the majority of individuals with whom we interact. However, if you wish to transfer your data from us to a third party, we are happy to consider such requests.",
      },
      {
        header3: "Your Right to object to processing",
      },
      {
        paragraph:
          "You may object to processing of your personal data where we rely on legitimate interest for processing that personal data.  We will comply with your request unless we have a compelling overriding legitimate interest for processing or we need to continue processing your personal data to establish, exercise or defend a legal claim.",
      },
      {
        header3: "Your Right to stop receiving communications",
      },
      {
        paragraph:
          "Where we send you e-mail marketing communications (or other regulated electronic messages) you have the right to opt-out at any time. You can do this by using the ‘unsubscribe’ link that appears in the footer of each communication (or the equivalent mechanism in those communications).",
      },
      {
        paragraph:
          "Alternatively, if for any reason you cannot use those links, or if you would prefer to contact us directly – you can unsubscribe by contacting us and telling us which communications you would like us to stop sending you.",
      },
      {
        header3:
          "Your Right to object to automated decision making and profiling",
      },
      {
        paragraph:
          "You have the right to be informed about the existence of any automated decision making and profiling of your personal data, and where appropriate, be provided with meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing that affects you.",
      },
      {
        header3: "Exercising your rights",
      },
      {
        paragraph:
          "When you write to us making a request to exercise your rights, we are entitled to ask you to prove that you are who you say you are. We may ask you to provide copies of relevant ID documents to help us to verify your identity.",
      },
      {
        paragraph:
          "It will help us to process your request if you clearly state which right you wish to exercise and, where relevant, why it is that you are exercising it. The clearer and more specific you can be, the faster and more efficiently we can deal with your request. If you do not provide us with sufficient information, then we may delay actioning your request until you have provided us with additional information (and where this is the case, we will tell you).",
      },
    ],
  },
  {
    type: "section",
    content: [
      {
        header2: "9.	Contact Details",
      },
      {
        paragraph:
          "If you have any queries regarding this Privacy Policy, if you wish to exercise any of your rights set out above or if you think that the Privacy Policy has not been followed, please contact us by emailing at getintouch@bitmoremedia.com. ",
      },
      {
        paragraph:
          "You may also lodge a complaint with our lead supervisory authority, the Information Commissioner, or your local supervisory authority about any aspect of our handling or processing of your personal data.  We would, however, appreciate the chance to address your concerns before you approach any supervisory authority, so please contact us in the first instance. ",
      },
    ],
  },
]
