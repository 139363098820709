import React from "react"

import SEO from "../../components/Seo"
import PageWrapper from "../../components/PageWrapper"

import PrivacyPolicy from "../../features/LegalStuff/PrivacyPolicy"

const PrivacyPolicyPage = ({ location }) => {
  return (
    <PageWrapper location={location}>
      <SEO title="Privacy Policy" />
      <PrivacyPolicy />
    </PageWrapper>
  )
}

export default PrivacyPolicyPage
